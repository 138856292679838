import { mapState, mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'ZbHeader',
  data: () => ({
    menu: false,
    searchText: '',
    showMissingNotification: false,
    countMissingSteps: 0,
    items: []
  }),
  computed: {
    ...mapState('settingsModule', ['dataSettings']),
    ...mapGetters('settingsModule', ['barId$', 'profileBarUrl$', 'titleBar$']),
    currentRouteName () {
      return this.$route.name
    },
    iconTitle () {
      return this.$route.meta.icon
    }
  },
  methods: {
    notifications () {
      const missingSteps = localStorage.getItem('missing-steps')
      if (missingSteps) {
        this.showMissingNotification = true
        const tmp = JSON.parse(missingSteps)
        this.items = _.compact(_.map(tmp, (v, k) => {
          if (!v) {
            switch (k) {
              case 'securityMeasures':
                return {
                  title: this.$t(`missingSteps.${k}`),
                  link: 'customization'
                }
              case 'schedule':
                return {
                  title: this.$t(`missingSteps.${k}`),
                  link: 'customization'
                }
              case 'menu':
                return {
                  title: this.$t(`missingSteps.${k}`),
                  link: 'product-tickets'
                }
              default:
                return {
                  title: this.$t(`missingSteps.${k}`),
                  link: 'profile'
                }
            }
          }
          return null
        }))
      } else {
        this.showMissingNotification = false
      }
    }
  }
}
