export default {
  name: 'ZbTheming',
  data () {
    return {
      head: null,
      style: null,
      stateTheme: null,
      buttons: [
        {
          color: 'pink',
          hex: '#C71585',
          active: true
        },
        {
          color: 'blue',
          hex: 'blue',
          active: false
        },
        {
          color: 'orange',
          hex: '#FFA500',
          active: false
        },
        {
          color: 'green',
          hex: '#32CD32',
          active: false
        },
        {
          color: 'yellow',
          hex: '#FFD700',
          active: false
        }
      ]
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'fas fa-trash'
    },
    dataSong: {
      type: Object
    }
  },
  created () {
    this.initialize()
  },
  methods: {
    dialogEvent () {
      this.$emit('input')
    },
    initialize () {
      this.head = document.head || document.getElementsByTagName('head')[0]
      this.style = document.createElement('style')
      this.style.type = 'text/css'
      this.style.setAttribute('id', 'stateTheme')
      this.head.appendChild(this.style)
      this.stateTheme = document.getElementById('stateTheme')
      if (localStorage.getItem('idTheme')) {
        if (localStorage.getItem('theme')) {
          this.stateTheme.innerHTML = localStorage.getItem('theme')
        }
        this.cleanActive()
        this.buttons[localStorage.getItem('idTheme')].active = true
      }
    },
    cleanActive () {
      this.buttons = this.buttons.map((v) => {
        return {
          color: v.color,
          hex: v.hex,
          active: false
        }
      })
    },
    themeActive (color, id) {
      const yellowTheme = `
        /**
         * CSS ColorVars Theme
         * @see https://github.com/CSSColorVars/csscolorvars
         */
        :root {
          --primary-color: #ffb100;
          --primary-HS: 42, 100%;
          --primary-HSL: 42, 100%, 50%;
          --secondary-color: #1a2427;
          --secondary-HS: 194, 20%;
          --secondary-HSL: 194, 20%, 13%;
          --accent-color: #ffc800;
          --accent-HS: 47, 100%;
          --accent-HSL: 47, 100%, 50%;
          --accent-2-color: #ff9200;
          --accent-2-HS: 34, 100%;
          --accent-2-HSL: 34, 100%, 50%;
          --text-color: #ffffff;
          --text-HS: 0, 0%;
          --text-HSL: 0, 0%, 100%;
          --error-color: #d72f2f;
          --error-HS: 0, 68%;
          --error-HSL: 0, 68%, 51%;
          --warning-color: #f56b46;
          --warning-HS: 13, 90%;
          --warning-HSL: 13, 90%, 62%;
          --success-color: #00bf38;
          --success-HS: 138, 100%;
          --success-HSL: 138, 100%, 37%;
          --info-color: #459fc7;
          --info-HS: 198, 54%;
          --info-HSL: 198, 54%, 53%;
        }
      `
      const orangeTheme = `
        /**
         * CSS ColorVars Theme
         * @see https://github.com/CSSColorVars/csscolorvars
         */
        :root {
          --primary-color: #fc6d21;
          --primary-HS: 21, 97%;
          --primary-HSL: 21, 97%, 56%;
          --secondary-color: #271e1a;
          --secondary-HS: 18, 20%;
          --secondary-HSL: 18, 20%, 13%;
          --accent-color: #fca421;
          --accent-HS: 36, 97%;
          --accent-HSL: 36, 97%, 56%;
          --accent-2-color: #e34125;
          --accent-2-HS: 9, 77%;
          --accent-2-HSL: 9, 77%, 52%;
          --text-color: #ffffff;
          --text-HS: 0, 0%;
          --text-HSL: 0, 0%, 100%;
          --error-color: #d72727;
          --error-HS: 0, 69%;
          --error-HSL: 0, 69%, 50%;
          --warning-color: #ffaf00;
          --warning-HS: 41, 100%;
          --warning-HSL: 41, 100%, 50%;
          --success-color: #3bb541;
          --success-HS: 123, 51%;
          --success-HSL: 123, 51%, 47%;
          --info-color: #459fc7;
          --info-HS: 198, 54%;
          --info-HSL: 198, 54%, 53%;
        }
      `
      const blueTheme = `
        /**
         * CSS ColorVars Theme
         * @see https://github.com/CSSColorVars/csscolorvars
         */
        :root {
          --primary-color: #2568ab;
          --primary-HS: 210, 64%;
          --primary-HSL: 210, 64%, 41%;
          --secondary-color: #1a1d27;
          --secondary-HS: 226, 20%;
          --secondary-HSL: 226, 20%, 13%;
          --accent-color: #239fd9;
          --accent-HS: 199, 72%;
          --accent-HSL: 199, 72%, 49%;
          --accent-2-color: #273a86;
          --accent-2-HS: 228, 55%;
          --accent-2-HSL: 228, 55%, 34%;
          --text-color: #ffffff;
          --text-HS: 0, 0%;
          --text-HSL: 0, 0%, 100%;
          --error-color: #d34141;
          --error-HS: 0, 62%;
          --error-HSL: 0, 62%, 54%;
          --warning-color: #ffaf00;
          --warning-HS: 41, 100%;
          --warning-HSL: 41, 100%, 50%;
          --success-color: #3bb541;
          --success-HS: 123, 51%;
          --success-HSL: 123, 51%, 47%;
          --info-color: #459fc7;
          --info-HS: 198, 54%;
          --info-HSL: 198, 54%, 53%;
        }
      `
      const greenTheme = `
        /**
         * CSS ColorVars Theme
         * @see https://github.com/CSSColorVars/csscolorvars
         */
        :root {
          --primary-color: #4fab25;
          --primary-HS: 101, 64%;
          --primary-HSL: 101, 64%, 41%;
          --secondary-color: #1a2627;
          --secondary-HS: 185, 20%;
          --secondary-HSL: 185, 20%, 13%;
          --accent-color: #51d923;
          --accent-HS: 105, 72%;
          --accent-HSL: 105, 72%, 49%;
          --accent-2-color: #338627;
          --accent-2-HS: 112, 55%;
          --accent-2-HSL: 112, 55%, 34%;
          --text-color: #ffffff;
          --text-HS: 0, 0%;
          --text-HSL: 0, 0%, 100%;
          --error-color: #d72727;
          --error-HS: 0, 69%;
          --error-HSL: 0, 69%, 50%;
          --warning-color: #ffaf00;
          --warning-HS: 41, 100%;
          --warning-HSL: 41, 100%, 50%;
          --success-color: #00bf94;
          --success-HS: 166, 100%;
          --success-HSL: 166, 100%, 37%;
          --info-color: #459fc7;
          --info-HS: 198, 54%;
          --info-HSL: 198, 54%, 53%;
        }
      `
      switch (color) {
        case 'zoundbar':
          this.stateTheme.innerHTML = this.zoundTheme()
          break
        case 'orange':
          this.stateTheme.innerHTML = orangeTheme
          break
        case 'blue':
          this.stateTheme.innerHTML = blueTheme
          break
        case 'green':
          this.stateTheme.innerHTML = greenTheme
          break
        case 'yellow':
          this.stateTheme.innerHTML = yellowTheme
          break
        default:
          this.stateTheme.innerHTML = this.zoundTheme()
      }
      this.cleanActive()
      this.buttons[id].active = true
      localStorage.setItem('theme', this.stateTheme.innerHTML)
      localStorage.setItem('idTheme', id)
    },
    zoundTheme () {
      return `
        /**
         * CSS ColorVars Theme
         * @see https://github.com/CSSColorVars/csscolorvars
         */
        :root {
          --primary-color: #ab257d;
          --primary-HS: 321, 64%;
          --primary-HSL: 321, 64%, 41%;
          --secondary-color: #1b1a27;
          --secondary-HS: 245, 20%;
          --secondary-HSL: 245, 20%, 13%;
          --accent-color: #d92376;
          --accent-HS: 333, 72%;
          --accent-HSL: 333, 72%, 49%;
          --accent-2-color: #7a2786;
          --accent-2-HS: 292, 55%;
          --accent-2-HSL: 292, 55%, 34%;
          --text-color: #ffffff;
          --text-HS: 0, 0%;
          --text-HSL: 0, 0%, 100%;
          --error-color: #d72f2f;
          --error-HS: 0, 68%;
          --error-HSL: 0, 68%, 51%;
          --warning-color: #ffaf00;
          --warning-HS: 41, 100%;
          --warning-HSL: 41, 100%, 50%;
          --success-color: #3bb541;
          --success-HS: 123, 51%;
          --success-HSL: 123, 51%, 47%;
          --info-color: #459fc7;
          --info-HS: 198, 54%;
          --info-HSL: 198, 54%, 53%;
        }
      `
    }
  }
}
