import ZbLayout from '@/templates/ZbLayout'
import { mapState, mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import { ServiceFactory } from '@/services/ServiceFactory'
const SettingsService = ServiceFactory.get('SettingsService')

export default {
  data () {
    return {
      intervalTime: null,
      counterInterval: null
    }
  },
  components: {
    ZbLayout
  },
  computed: {
    ...mapState('authModule', ['data']),
    ...mapGetters({
      authCredentials$: 'authModule/authCredentials'
    })
  },
  created () {
    if (this.authCredentials$.zbRole === 'MUSIC') {
    }
    this.getSettings()
  },
  methods: {
    ...mapActions('authModule', ['logout']),
    ...mapActions({
      setSettings: 'settingsModule/setSettings'
    }),
    getSettings () {
      SettingsService.get().then((resp) => {
        if (!resp.availableForPublic) {
          // this.showMissingStepsForComplete(resp.profileCompleted)
          localStorage.setItem('missing-steps', JSON.stringify(resp.profileCompleted))
        } else {
          localStorage.removeItem('missing-steps')
        }
        this.setSettings({ data: resp })
      }).catch((err) => {
        if (!err.activeBar) {
          this.$notify({
            title: 'Error:',
            type: 'error',
            text: err.msg,
            closeOnClick: true,
            duration: 9000
          })
          this.logOutSubmit()
        } else {
          this.$notify({
            title: 'Error:',
            type: 'error',
            text: err.msg
          })
        }
      })
    },
    async logOutSubmit () {
      await this.logout({ url: 'auth/logout' })
      this.$swal({
        title: this.$t('authentication.accessDenied'),
        text: this.$t('authentication.accessMessage'),
        icon: 'error'
      })
      if (!this.authCredentials$) {
        this.$router.push({ name: 'login' })
      }
    },
    showMissingStepsForComplete (data) {
      let htmlTemplatee = ''
      const chekIcon = 'fas fa-check-circle'
      const wrongIcon = 'fas fa-times-circle'
      _.each(data, (v, k) => {
        htmlTemplatee += `
            <label> <b>${this.$t(`missingSteps.${k}`)}</b>  <button><i class="${v ? chekIcon : wrongIcon}"></i></button></label><br><br>
        `
      })
      this.$swal({
        title: `<strong>${this.$t('missingSteps.title')}</strong>`,
        imageHeight: 1500,
        icon: 'info',
        html: htmlTemplatee,
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: 'Ok'
      })
    }
  }
}
