export default {
  name: 'ZbPreloader',
  data: () => ({
    show: true
  }),
  mounted () {
    this.showToggle()
  },
  methods: {
    showToggle () {
      setTimeout(() => {
        this.show = false
      }, 1500)
    }
  }
}
