import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import ZbTheming from './zb-theming'
export default {
  name: 'ZbSideBar',
  components: { ZbTheming },
  data () {
    return {
      navMini: false,
      menuActive: false,
      dialog: false,
      enableStep: true,
      id: null,
      count: 100,
      right: null,
      show: true,
      windowSize: {
        x: 0,
        y: 0
      }
    }
  },
  mounted () {
    const mediumBp = matchMedia('(min-width: 1024px)')
    const changeSize = mql => {
      mql.matches
        ? this.navMini = false
        : this.navMini = true
    }
    mediumBp.addListener(changeSize)
    changeSize(mediumBp)
  },
  computed: {
    ...mapState('uiModule', ['miniSidebar']),
    ...mapGetters({
      authCredentials$: 'authModule/authCredentials',
      typeBusiness$: 'settingsModule/typeBusiness'
    }),
    ...mapGetters('settingsModule', ['profileBarUrl$', 'titleBar$']),
    logoZoundHome () {
      return `${process.env.BASE_URL}images/zoundhome.png`
    }
  },
  methods: {
    ...mapMutations('uiModule', ['MINI_SIDEBAR']),
    ...mapActions('authModule', ['logout']),
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    async logOutSubmit () {
      await this.logout({ url: 'auth/logout' })
      localStorage.removeItem('missing-steps')
      if (!this.authCredentials$) {
        this.$router.push({ name: 'login' })
      }
    },
    gotoPolitics () {
      window.open('https://reserbar.vip/politicas-restaurantes', '_blank')
    },
    gotoManual () {
      window.open('https://api.reserbar.vip/static/pdf/manual_reserbar-2.pdf', '_blank')
    },
    openSupport () {
      window.open('https://api.whatsapp.com/send?phone=59175880315&text=Necesito%20ayuda%20con%20la%20app%20Reserbar', '_blank')
    }
  }
}
