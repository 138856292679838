<template>
<v-app>
  <zb-preloader></zb-preloader>
  <div class="tw-flex">
    <Sidebar />
    <section class="tw-pl-0 tw-flex-1 tw-w-full tw-bg-secondary-100 tw-min-h-screen">
      <zb-header />
      <main class="px-4 py-4">
        <slot></slot>
      </main>
    </section>
  </div>
</v-app>
</template>

<script>
import ZbPreloader from './zb-preloader'
import ZbHeader from './zb-header'
import Sidebar from './sidebar'
export default {
  name: 'ZbLayout',
  components: {
    ZbPreloader,
    ZbHeader,
    Sidebar
  }
}
</script>
